export function setToken(key,value){
    window.localStorage.setItem(key,JSON.stringify(value))
}
export function getToken(key){
    return JSON.parse(window.localStorage.getItem(key))
}
export function removeToken(key){
   window.localStorage.removeItem(key)
}
export function clearToken(){
    window.localStorage.clear()
 }