<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view ></router-view>
    </transition>
    <!-- <footer-bar v-show="isTabFlag" :isTabFlag="isTabFlag"></footer-bar> -->
  </div>
</template>
<script>
import { setToken , getToken } from './util/util'
import footerBar from '@/components/footer/index.vue'
export default {
  name: 'App',
  components:{
      footerBar
  },
  data(){
    return{
      isViewAlive:true,
      isTabFlag:1,
      activeIndex:0,
    }
  },
  created(){
    // this.getSignature();
  },
  mounted(){
    this.$EventBus.$on('shareContent',(flag,obj)=>{
      //flag true false,true 直接使用传过来的obj,false则使用store中的state数据
      //  this.share(flag,obj);
    });
  },
  watch: {
    /**
     * 监听路由，自动切换底部tab
     */
    $route(to, from) {
      if(to.meta.title){
        document.title = to.meta.title;
      }
      // if(to.meta.showShareOption){
      //   wx.showOptionMenu();  //显示转发按钮
      //   this.share(false,to.name);
      //   // wx.showMenuItems({
      //   //   menuList: ["menuItem:share:appMessage","menuItem:share:timeline"] // 要显示的菜单项，所有menu项见附录3
      //   // });
      // }else{
      //    wx.hideOptionMenu();  //隐藏转发按钮
      // }
      console.log('daewe',to)
      
      // this.isTabFlag = this.isTabPage(to.name);
      // console.log('isTabFlag',this.isTabFlag)
      // if(to.meta.requireLogin){
      //     let userinfo = getToken('userInfo');
      //     if(!userinfo){
      //       let params = {
      //         appid:process.env.VUE_APP_APPID,  
      //         redirect_uri:process.env.VUE_APP_USESR_SERVER+'/callback',//回调地址
      //         response_type:'code',//返回类型，请填写code
      //         scope:'snsapi_userinfo', //弹出授权页面
      //       }
      //       let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${params.appid}&redirect_uri=${params.redirect_uri}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
      //       console.log('url',url)
      //       location.href = url;
      //     }
      // }
     



      

   
    
    }
  },
  methods:{
    //判断是否是tab页面
    isTabPage(page){
      let status = 0;
      let pageList = ['/card','/case','/offcialWebsite','/my'];
      for(let i = 0;i<pageList.length;i++){
        if(page == pageList[i]){
          status = i+1;
        }
      }
      return status
    },
      /**
     * 重新加载组件
     */
    // reloadView() {
    //   this.isViewAlive = false
    //   this.$nextTick(() => {
    //     this.isViewAlive = true
    //   })
    // },
  //   share(flag,address){
  //      let shareObj =null;
  //       if(!flag){
  //         let shareItem = this.$store.state.shareList.filter(item=>{
  //           if(item.page.indexOf(address)>-1){
  //             return item
  //           }
  //        });
  //        shareObj = shareItem[0].shareContentObj;
  //       }else{
  //         shareObj = address;
  //       }
  //       console.log('shareObj',shareObj)
  //       wx.ready(function(){
  //         wx.updateAppMessageShareData(shareObj)
  //          wx.updateTimelineShareData(shareObj)
  //       });
  //   },
  //   getSignature(){
  //     let url =encodeURIComponent(location.href.split('#')[0]);
  //     this.$http.get('/user_service/wplatform/get_share_signature?base_url='+url).then(res=>{
  //       if(res.code == 0){
  //         wx.config({
  //           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //           appId: process.env.VUE_APP_APPID, // 必填，公众号的唯一标识
  //           timestamp:res.data.timestamp, // 必填，生成签名的时间戳
  //           nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
  //           signature: res.data.signature,// 必填，签名
  //           jsApiList: ['updateAppMessageShareData','updateTimelineShareData'] // 必填，需要使用的JS接口列表
  //         });
         
  //       }
  //     })
  //   },
  }


};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#2B2B2B;
  font-size:14px;
}
blockquote, body, button, dd, dl, dt, fieldset, form, h1, h2, h3, h4, h5, h6, hr, input, legend, li, ol, p, pre, td, textarea, th, ul {
    margin: 0;
    padding: 0;
}
/***************** 公共动画效果 *****************/
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease;
}
.fade-enter-to, .fade-leave {
  opacity: 1;
}


</style>
