import Vue from 'vue'

import Vuex from 'vuex'

Vue.use(Vuex)

import shareList from '../util/share'

export default new Vuex.Store({

  state: {
    //这里放全局参数
    shareList:shareList,
    status:1,
  },
  mutations: {
    setShareList(state,data) {
        let shareList = state.shareList;
        shareList.forEach(item=>{
            if(item.page.indexOf(data.page)>-1){
                item.shareContentObj = data.shareContentObj
            }
        })
        state.shareList = shareList;
        console.log('state',state);
    },
    setStatus(state,data){
        state.status = data;

    }
  },

 getters: {        
    //这里是get方法  
    userState: state => state.userState
 },

  actions: {
   
    //这个部分我暂时用不上

  },

  modules: {

//这里是我自己理解的是为了给全局变量分组，所以需要写提前声明其他store文件，然后引入这里

  }

})