<template>
    <div class="footer">
        <!-- <mt-tabbar v-model="selected">
        <mt-tab-item id="/">
            <img slot="icon" src="">
            名片
        </mt-tab-item>
        <mt-tab-item id="/case">
            <img slot="icon" src="">
            商家案例
        </mt-tab-item>
        <mt-tab-item id="/offcialWebsite">
            <img slot="icon" src="">
            官网
        </mt-tab-item>
        <mt-tab-item id="/my">
            <img slot="icon" src="">
            我的
        </mt-tab-item>
        </mt-tabbar> -->
        <ul>
            <li v-for="item in tabList" :key="item.id" @click.stop="handle(item)" :class="selected == item.id?'active':''">
                <img v-show="isActive(item.id)" :src="item.imgActive" alt="">
                <img v-show="!isActive(item.id)" :src='item.img' alt="">
                <p>{{item.name}}</p>
            </li>
           
        </ul>
    </div>
</template>
<script>
export default {
    name:'footerBar',
    props:{
        isTabFlag:{
            type:Number
        }
    },
    watch:{
        isTabFlag(val){
            console.log('val',val)
            this.selected = val || 1;
        },
    },
   
    data(){
        return{
            selected:1,
            tabList:[
                {
                    name:'名片',
                    id:1,
                    location:'/card',
                    img:require('@/assets/card.png'),
                    imgActive:require('@/assets/cardActive.png')
                },
                {
                    name:'案例',
                    id:2,
                    location:'/case',
                    img:require('@/assets/case.png'),
                    imgActive:require('@/assets/caseActive.png')
                },
                {
                    name:'官网',
                    id:3,
                    location:'/offcialWebsite',
                    img:require('@/assets/web.png'),
                    imgActive:require('@/assets/webActive.png')
                },
                {
                    name:'我的',
                    id:4,
                    location:'/my',
                    img:require('@/assets/my.png'),
                    imgActive:require('@/assets/myActive.png')
                }
            ]
        }
    },
    computed:{
        
    },
    methods:{
        isActive(index){
            return this.selected == index;
        },
        handle(item){
            this.selected = item.id;
            this.$router.replace({
                path:item.location
            })
            
        }

    }
}
</script>
<style lang="scss" scoped>
.footer{
    width:100%;
    position: fixed;
    bottom:0;
    left:0;
    // border-top:1px solid #bfbfbf;
    background: #fff;
    z-index: 6;
    ul{
        width:100%;
        height:56px;
        display: flex;
        align-items: center;
        li{
            flex:1;
            list-style: none;
            text-align: center;
            font-size:10px;
            color:#333333;
            img{
                display: block;
                width:28px;
                height:28px;
                margin:0px auto 0;
            }
        }
        .active{
            color:$colorActive;
        }

    }
} 
    
</style>