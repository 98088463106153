import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router'
import store from './store'
import routes from './router'
import MintUI from 'mint-ui'
import service from './util/service'
import Clipboard from 'clipboard';
import axios from 'axios'

// vant 全局配置
import Vant,{Popup} from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)
Vue.use(Popup)

import './util/rem'







import {
  /* eslint-disable no-unused-vars */
  Tabbar,
  TabItem,
  Toast,
  Indicator,
  // Popup ,
  Switch ,
} from 'mint-ui'

import 'mint-ui/lib/style.min.css';
Vue.component(Tabbar.name,Tabbar)
Vue.component(TabItem.name,TabItem)
Vue.component(Popup.name,Popup)
Vue.component(Switch.name,Switch)
// Vue.use(Indicator)
Vue.prototype.$Indicator = Indicator;
Vue.prototype.$http = service;
Vue.prototype.$Toast = Toast;
Vue.prototype.$store = store;

Vue.config.productionTip = false;
 

Vue.prototype.Clipboard=Clipboard;

let instance = null;

Vue.prototype.$EventBus = new Vue();


// router.beforeEach((to, from, next) => {
//   if(to.meta.title){
//     document.title = to.meta.title
//   }
//    /*判断路由是否需要权限才能进入，即路由拦截 */
//    if(to.meta.requireLogin){
//     if(true){ //从vuex里面获取userId
//       next()
//     }else{
//       next({path:'/login'})
//     }
//   }
//   next();
// })


// new Vue({
//   render: (h) => h(App),
//   router,
// }).$mount('#app');


if (!window.__POWERED_BY_QIANKUN__) {
  // 这里是子应用独立运行的环境，实现子应用的登录逻辑

  // 独立运行时，也注册一个名为global的store module
  // commonStore.globalRegister(store)
  // 模拟登录后，存储用户信息到global module
  // const userInfo = { name: '我是独立运行时名字叫张三' } // 假设登录后取到的用户信息
  // store.commit('global/setGlobalState', { user: userInfo })

  render()
}
console.log('routes',routes)
function render (props = {}) {
  const { routerBase } = props
  const router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? routerBase : process.env.BASE_URL,
    mode: 'history',
    routes
  })
  instance = new Vue({
    router,
    render: (h) => h(App)
  }).$mount('#app')

  
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
   /*判断路由是否需要权限才能进入，即路由拦截 */
   if(to.meta.requireLogin){
    if(true){ //从vuex里面获取userId
      next()
    }else{
      next({path:'/login'})
    }
  }
  next();
})
}

/**
 * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
 * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
 */
 export async function bootstrap () {
  console.log('[vue] vue app bootstraped')
}

/**
 * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
 */
export async function mount (props) {
  console.log('[vue] props from main framework', props)

  // commonStore.globalRegister(store, props)

  render(props)
}

/**
 * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
 */
export async function unmount () {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
}

/**
 * 可选生命周期钩子，仅使用 loadMicroApp 方式加载微应用时生效
 */
export async function update(props) {
  console.log('update props', props)
}


