import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
    {
        path:'/card',
        name:'/card',
        // component:card,
        component:()=>import('@/views/businessCard/card/index.vue'),
        meta: {
            showFooter: true,
            showHeader:true,
            requireLogin:true,
            title:'名片',
            showShareOption:true,
        }
    },
    {
        path:'/case',
        name:'/case',
        // component:caseIndex,
        component:()=>import('@/views/caseBase/case/index.vue'),
        meta: {
            showFooter: true,
            showHeader:true,
            requireLogin:true,
            title:'案例',
            showShareOption:true,
        }
    },
    {
        path:'/detail',
        name:'/detail',
        component:()=>import('@/views/caseBase/case/detail.vue'),
        meta: {
            showFooter: true,
            showHeader:true,
            requireLogin:true,
            title:'案例详情',
            showShareOption:true,
        }
    },
    {
        path:'/casePoster',
        name:'/casePoster',
        component:()=>import('@/views/caseBase/case/casePoster.vue'),
        meta: {
            showFooter: false,
            showHeader:true,
            requireLogin:true,
            title:'生成海报页',
            showShareOption:true,
        }
    },
    {
        path:'/my',
        name:'/my',
        // component:my,
        component:()=>import('@/views/my/index.vue'),
        meta: {
            showFooter: true,
            showHeader:true,
            requireLogin:true,
            title:'个人中心',
            showShareOption:false,
        }
    },
    {
        path:'/offcialWebsite',
        name:'/offcialWebsite',
        // component:offcialWebsite,
        component:()=>import('@/views/offcialWebsite/index.vue'),
        meta: {
            showFooter: true,
            showHeader:true,
            requireLogin:true,
            title:'官网',
            showShareOption:false,
        }
    },
    {
        path:'/login',
        name:'/login',
        component:()=>import('@/views/login/index.vue'),
        meta: {
            showFooter: false,
            showHeader:true,
            requireLogin:true,
            title:'微信授权',
            showShareOption:false,
        }
    },
    {
        path:'/search',
        name:'/search',
        component:()=>import('@/views/caseBase/case/search.vue'),
        meta: {
            showFooter: false,
            showHeader:true,
            requireLogin:true,
            title:'商家案例',
            showShareOption:false,
        }
    },
    {
        path:'/callback',
        name:'/callback',
        component:()=>import('@/views/tips/callback.vue'),
        meta: {
            showFooter: false,
            showHeader:false,
            requireLogin:false,
            title:'微信登录',
            showShareOption:false,
        }
    },
    {
        path:'/collect',
        name:'/collect',
        component:()=>import('@/views/collect/index.vue'),
        meta: {
            showFooter: true,
            showHeader:true,
            requireLogin:true,
            title:'我的收藏',
            showShareOption:false,
        }
    },
]

export default routes;