const origin = window.origin;
const shareList = [
    {
        page:['/case'],
        shareContentObj:{
            title:'如何用好小鹅通知识店铺，从这里可以找到你想要的答案',
            desc:'点击查看店铺案例',
            link:origin+'/subapp/sub_users/case',
            imgUrl:'http://wechatapppro-1252524126.file.myqcloud.com/apprnDA0ZDw4581/image/0398fe634cc41cd9fa619772271b7c11.png',
            success:function(){
                
            }
        }
    },
    {
        page:['/detail','/casePoster'],
        shareContentObj:{
            title:'案例标题',
            desc:'案例描述',
            link:origin+'/subapp/sub_users/caseDetail',
            imgUrl:'http://wechatapppro-1252524126.file.myqcloud.com/apprnDA0ZDw4581/image/0398fe634cc41cd9fa619772271b7c11.png',
        }
    },
    {
        page:['/card'],
        shareContentObj:{
            title:'您好，我是小鹅通销售经理，这个是我的名片，请惠存，谢谢。',
            desc:'点击查看名片',
            link:origin+'/subapp/sub_users/card',
            imgUrl:'',
        }
    },
    {
        page:['/coupon'],
        shareContentObj:{
            title:'给你分享了一张限时优惠券',
            desc:'点击领取超值优惠券',
            link:origin+'/subapp/sub_users/coupon',
            imgUrl:'',
        }
    }
]
export default shareList;