import axios from 'axios'
import router from '../router'
import { Indicator, Toast } from 'mint-ui'
import {getToken,setToken} from './util'

const service = axios.create({
    baseUrl:process.env.TARGET,
    timeout:5000,
})

//request拦截器，在请求之前做点什么
service.interceptors.request.use(
    config =>{
        const userInfo = getToken('userInfo')
        if (userInfo&&userInfo.token) { //  header都加上token
          config.headers.token = userInfo.token  //请求头加上token
        }
        Indicator.open('加载中')
        return config
    },
    error =>{
        Promise.reject(error)
    }
)
service.interceptors.response.use(
    response =>{
        Indicator.close();
        const res = response.data;
        if(res.code == 0){
            return res;
        }else if(res.code == 401){
            // Toast({
            //     message:'登录失效，正在重新登录',
            //     duration:100
            // })
            // Indicator.open('重新登录中...')
            // login();
            // return res;
        }else{
            // return res;
            // Toast(res.msg)
            Promise.reject(res.msg)
        }
    },
    error =>{
        console.log('error',error)
        Toast(error)
        Indicator.close();
        Promise.reject('网络异常')
    }
)
let userInfo = getToken('userInfo');

function login(){
    console.log('userInfo',userInfo)
    let headers = {
        token:userInfo.token
    }
    axios.post('/user_service/user/login',{},{headers}).then(res=>{
        console.log('res',res);
        Indicator.close();
        const result = res.data;
        console.log('res',result)
        if(result.code === 0){
            setToken('userInfo',result.data);
            Toast({
                message:'登录成功',
                duration:3000
            })
            // window.location.reload();
        }else{
            Toast({
                message:result.msg || '登录失败',
                duration:3000
            })
        }
    }).catch(err=>{
        Indicator.close();
        Toast({
            message:err.msg || '登录失败',
            duration:3000
        })
    })
}

export default service;